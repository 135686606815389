<template>
  <v-row>
    <v-col
      cols="12"
      class="mb-6"
    >
      <v-card>
        <v-card-title>Headlines</v-card-title>
        <v-row class="ma-0 pb-5 px-2">
          <v-col
            cols="3"
            sm="2"
          >
            <h1>
              About
            </h1>
          </v-col>
          <v-col
            cols="9"
            sm="10"
          >
            <h1>
              About
            </h1>
            <span>font-size: 32px / line-height: 1.5 / font-weight: 600</span>
          </v-col>
        </v-row>
      </v-card>
      <v-card>
        <v-card-title>About VITCGovernance</v-card-title>
        <v-row class="ma-0 pb-5 px-2">
          <v-col
            cols="3"
            sm="2"
          >
            <span class="subtitle-1 text-no-wrap">subtitle-1</span>
          </v-col>
          <v-col
            cols="9"
            sm="10"
          >
            <p class="subtitle-1 text-truncate mb-0">
              Important decisions need to be made and governed in the Vitamin Coin community, with votes made by VITC holders. Similarly, key decisions for eDAO require voting by token holders.
            </p>
            <small>font-size: 1rem / line-height: 1.75rem / font-weight: 400</small>
          </v-col>

          <v-col
            cols="3"
            sm="2"
          >
            <span class="subtitle-2 text-no-wrap">subtitle-2</span>
          </v-col>
          <v-col
            cols="9"
            sm="10"
          >
            <p class="subtitle-2 mb-0">
              Important decisions need to be made and governed in the Vitamin Coin community, with votes made by VITC holders. Similarly, key decisions for eDAO require voting by token holders.
            </p>
            <small>font-size: 0.875rem / line-height: 1.375rem / font-weight: 500</small>
          </v-col>

          <v-col
            cols="3"
            sm="2"
          >
            <span class="body-1 text-no-wrap">body-1</span>
          </v-col>
          <v-col
            cols="9"
            sm="10"
          >
            <p class="body-1 mb-0">
              Important decisions need to be made and governed in the Vitamin Coin community, with votes made by VITC holders. Similarly, key decisions for eDAO require voting by token holders.
            </p>
            <small>font-size: 1rem / line-height: 1.5rem / font-weight: 400</small>
          </v-col>

          <v-col
            cols="3"
            sm="2"
          >
            <span class="body-2 text-no-wrap">body-2</span>
          </v-col>
          <v-col
            cols="9"
            sm="10"
          >
            <p class="body-2 mb-0">
              Important decisions need to be made and governed in the Vitamin Coin community, with votes made by VITC holders. Similarly, key decisions for eDAO require voting by token holders.
            </p>
            <small>font-size: 0.875rem / line-height: 1.25rem / font-weight: 400</small>
          </v-col>

          <v-col
            cols="3"
            sm="2"
          >
            <span class="caption">caption</span>
          </v-col>
          <v-col
            cols="9"
            sm="10"
          >
            <p class="caption mb-0">
              Important decisions need to be made and governed in the Vitamin Coin community, with votes made by VITC holders. Similarly, key decisions for eDAO require voting by token holders.
            </p>
            <small>font-size: 0.75rem / line-height: 1.25rem / font-weight: 400</small>
          </v-col>

          <v-col
            cols="3"
            sm="2"
          >
            <span class="overline text-no-wrap">overline</span>
          </v-col>
          <v-col
            cols="9"
            sm="10"
          >
            <p class="overline mb-0">
              Important decisions need to be made and governed in the Vitamin Coin community, with votes made by VITC holders. Similarly, key decisions for eDAO require voting by token holders.
            </p>
            <small>font-size: 0.75rem / line-height: 2rem / font-weight: 500</small>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {}
</script>
